import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContentHero from '../components/ContentHero'
import CardItem from '../components/CardItem'

const JobsPage = props => {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  return (
    <Layout>
      <Hero
        title="Mitgestalter"
        subtitle="Werte zu schaffen ist unsere Leidenschaft"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="Finde die perfekte Rolle für dich!"
          subtitle="Wir suchen immer wieder Menschen, die zu uns passen, unsere Werte und Ansprüche teilen. Du willst mitgestalten? Du bist ein echter Teamplayer und willst Dinge voran bringen? Dann schau dir unsere offenen Stellen an! Wir freuen uns über eine Bewerbung!"
        />
        <section className="section">
          <div className="container">
            <div className="columns is-multiline">
              {edges.map(({ node }) => (
                <div className="column is-one-third" key={node.id}>
                  <CardItem
                    image={node.frontmatter.image}
                    title={node.frontmatter.title}
                    tags={node.frontmatter.tags}
                    slug={node.fields.slug}
                    cardItemType="career"
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

JobsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default JobsPage

export const pageQuery = graphql`
  query JobOfferQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "job-offer" }, preview: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(height: 256)
              }
            }
            title
            date(formatString: "MMMM DD, YYYY")
            tags
          }
        }
      }
    }
    hero: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
