import React from 'react'
import PropTypes from 'prop-types'

const ContentHero = ({ title, subtitle }) => (
  <div>
    <div className="hero content-hero mb-5" style={{ margin: 'auto' }}>
      <div className="hero-body">
        <p className="title has-text-primary is-size-1">
          <span style={{ borderBottom: '3px' }}>{title}</span>
        </p>
        <p className="subtitle mt-2 has-text-justified">{subtitle}</p>
      </div>
    </div>
  </div>
)

ContentHero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default ContentHero
